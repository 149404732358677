.homePage_main_container {
  min-height: 100vh;
  background: linear-gradient(111.34deg, #152945 0%, #0c2d61 100%);
  padding: 123px 0;

  @media (max-width: 900px) and (min-width: 600px) {
    padding: 70px 0;
    min-height: 100vh;
  }

  @media (max-width: 595px) {
    padding: 50px 0;
    min-height: 125vh;
  }

  .brandLogo {
    img {
      width: 120px;
      height: 100px;

      @media (max-width: 595px) {
        width: 100px;
        height: 75px;
      }

      @media (max-width: 900px) and (min-width: 600px) {
        width: 117px;
        height: 80px;
      }
    }
  }

  //   lang drowpdown
  .languageDropdown_homePage {
    position: relative;
    top: -35px;

    @media (max-width: 595px) {
      top: 0;
    }

    @media (max-width: 900px) and (min-width: 600px) {
      top: 0;
    }

    .languageDropdown_FormCcontrol_homePage {
      @media (max-width: 900px) and (min-width: 600px) {
        min-width: 125px;
      }

      @media (max-width: 595px) {
        min-width: 120px;
      }
    }

    .MuiInputBase-root {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-align: right;
      letter-spacing: 0.02em;
      color: #fff;
      min-width: 131px;
      height: 40px;
      justify-content: center;
      text-transform: uppercase;

      @media (max-width: 900px) and (min-width: 600px) {
        min-width: 125px;
        height: 36px;
        font-weight: 500;
      }

      @media (max-width: 595px) {
        font-size: 13px;
        min-width: 120px;
        height: 36px;
        font-weight: 500;
      }

      em {
        font-style: normal;
      }

      .MuiSelect-select {
        display: flex;
        justify-content: flex-start;
        padding: 0;
        padding-left: 20px !important;
      }

      fieldset {
        border: 1px solid #fff;
        height: 40px;

        @media (max-width: 900px) and (min-width: 600px) {
          height: 38px;
        }

        @media (max-width: 595px) {
          height: 38px;
        }
      }
    }
  }

  .cardArea_btn {
    margin: 3rem 0;
    position: relative;

    .gridSec_left {
      padding: 4rem 0 4rem 15px;

      @media (max-width: 900px) and (min-width: 600px) {
        padding: 2rem 0 2rem 15px;
      }

      @media (max-width: 595px) {
        padding: 2rem 0 3rem 15px;
      }

      p {
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        color: #ffffff;

        @media (max-width: 900px) and (min-width: 600px) {
          font-size: 24px;
          line-height: 35px;
          text-align: left;
          max-width: 400px;
        }

        @media (max-width: 595px) {
          font-size: 27px;
          line-height: 35px;
          text-align: center;
        }
      }

      .btnArea_card {
        display: flex;
        justify-content: flex-start;
        gap: 25px;
        align-items: baseline;
        margin-top: 50px;

        @media (max-width: 900px) and (min-width: 600px) {
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          margin-top: 27px;
        }

        @media (max-width: 595px) {
          justify-content: center;
          flex-direction: column;

          a {
            margin-left: 58px;
          }
        }



        a {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
          width: 274px;
          height: 67px;
          border-radius: 6px;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          box-shadow: none;
          text-decoration: none;
          cursor: pointer;

          @media (max-width: 900px) and (min-width: 600px) {
            width: 224px;
            height: 58px;
            border-radius: 6px;
            font-weight: 700;
            font-size: 16px;
            line-height: 25px;
          }

          @media (max-width: 595px) {
            width: 254px;
            height: 64px;

            // a:nth-child(1) {
            //   margin-left: -40px;
            // }
          }


          @media (max-width: 395px) {
            width: 244px;
            height: 62px;
          }

          @media (max-width: 335px) {
            width: 184px;
            height: 58px;
          }
        }

        .requestBtn {
          color: #39c997;
          border: 3px solid #39c997;
          text-transform: uppercase;
          margin-right: 35px;
          font-family: Manrope;
          font-weight: 700;
          font-size: 17px;

          @media (max-width: 900px) and (min-width: 600px) {
            font-size: 13px !important;
            margin-right: 0;
            margin-bottom: 17px;
          }

          @media (max-width: 395px) {
            margin-right: 0;
            margin-bottom: 35px;
          }
        }

        .request_bt_position {
          @media(max-width :1160px) {
            position: relative;
            top: -12px;
            padding: 10px;
          }
        }

        .topupBtn_bt_position {
          @media(max-width :1160px) {
            padding: 10px;
          }
        }

        .topupBtn {
          background: #25b583;
          text-transform: uppercase;
          color: #fff;
          border: 0;
          font-family: Manrope;
          font-weight: 700;
          font-size: 18px;
          line-height: 24.59px;

          @media (min-width : 900px) and (max-width : 1024px) {
            // position: relative;
            // top: 2px;
          }
        }
      }
    }

    .cardImg_top {
      position: absolute;
      right: 0;
      top: 0;

      @media (max-width: 595px) {
        right: 0;
        top: auto;
        margin-right: 10px;
      }

      img {
        @media (max-width: 900px) and (min-width: 600px) {
          width: 100%;
          height: 300px;
        }

        @media (max-width: 595px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  @media(min-width: '500px') and (max-width : '1100px') {
    min-height: 190vh;
    padding-left: 90px;

    .cardImg_top {
      position: absolute !important;
      top: 338px !important;
    }
  }
}