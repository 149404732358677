.requestRefundCard_main_container {
  .formArea_requestRefund_main {
    max-width: 100%;
    margin-top: 15px;
    // padding: 20px;

    .rfidPass_num {
      .inputLabel {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: white;
        text-align: left;
        margin-top: 5px;
        margin-bottom: 10px;

        @media (max-width: 600px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .MuiInputBase-root {
        padding: 12px 20px;
        max-width: 100%;
        height: 54px;
        border: 1px solid white;
        border-radius: 6px;

        @media (max-width: 600px) {
          max-width: 100%;
        }

        @media (max-width: 500px) {
          max-width: 100%;
        }

        @media (max-width: 414px) {
          max-width: 100%;
        }

        @media (min-width: 1024px) and (max-height: 1100px) {
          max-width: 100%;
        }

        @media (min-width: 1440px) {
          max-width: 100%;
        }

        &::placeholder,
        &::-moz-placeholder,
        &::-webkit-input-placeholder {
          color: white !important;
        }

        input {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: white !important;

          &::placeholder,
          &::-moz-placeholder,
          &::-webkit-input-placeholder {
            color: white !important;
          }
        }

        &:after,
        &:before {
          border-bottom: 0 !important;
        }
      }
    }

    .labelText_checkbox {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: white;
      margin-bottom: 0;

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0;
      }

      span {
        color: white;
        text-decoration: underline;
      }
    }

    .gridEmpty_refund {
      @media (min-width: 595px) {
        display: none;
      }
    }

    button {
      padding: 15px 0px;
      width: 250px;
      height: 57px;
      background: transparent;
      border: 2px solid #ffd667;
      border-radius: 6px;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #ffd667;
      margin-top: 40px;
      box-shadow: none;

      @media (max-width: 768px) {
        width: 100%;
      }
      @media (max-width: 595px) {
        width: 100%;
        height: 55px;
        background: transparent;
        border-radius: 6px;
        font-weight: 600;
        font-size: 20px;
      }

      &:disabled {
        background: lightgray;
        color: white;
        border: none;
      }
    }

    button:hover {
      background: #ffd667;
      color: white;
      transition: all ease-in 0.2s;
    }
  }
}

.error-message {
  color: #cc0033;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  margin: 5px 0 0;
}

.requestRefundCard_main_container
  .formArea_requestRefund_main
  input::placeholder {
  color: white !important;
  opacity: 100 !important;
  -webkit-text-fill-color: white !important;
}

.form-group > div > input:disabled {
  color: white !important;
  opacity: 100 !important;
  -webkit-text-fill-color: white !important;
}

.checkbox {
  color: white !important;
}
