.successModal_main_container {
  .MuiPaper-root {
    width: 400px;
    min-width: 373px;
    min-height: 500px;
    border-radius: 10px;
    padding: 40px 30px;
  }

  .error-png {
    width: 100px;
    margin-bottom: 10px
  }

  .MuiDialogContent-root {
    padding: 0 2rem !important;

    h3 {
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      color: #000000;
    }

    .modalsuccessText {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      max-width: 373px;
      color: #5d5d5d;
      position: relative;
      top: 10px;
    }
  }

  .ActionsBtn {
    position: relative;

    .confirmBtn {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      box-shadow: none;
      text-align: center;
      color: #fff;
      width: 314px;
      height: 49px;
      background: #4263eb;
      border-radius: 10px;
    }
  }
}

.successModal_main_container_topup {
  .MuiPaper-root {
    width: 400px;
    min-width: 373px;
    min-height: 400px;
    border-radius: 10px;
    padding: 40px 30px;
  }

  .error-png {
    width: 100px;
    margin-bottom: 10px
  }

  .MuiDialogContent-root {
    padding: 0 2rem !important;

    h3 {
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      color: #000000;
    }

    .modalsuccessText {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      max-width: 373px;
      color: #5d5d5d;
      position: relative;
      top: 10px;
    }
  }

  .ActionsBtn {
    position: relative;

    .confirmBtn {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      box-shadow: none;
      text-align: center;
      color: #fff;
      width: 314px;
      height: 49px;
      background: #4263eb;
      border-radius: 10px;
    }
  }
}