.topupCard_main_container {
  .formArea_topup_main {
    max-width: 100vw;
    margin-top: 25px;

    .display_error {
      display: none;
    }

    .MuiSelect-select {
      color: white !important;
    }

    @media (max-width: 414px) {
      // padding-left: 10px;
    }

    .rfidPass_num {
      @media (max-width: 500px) {
        width: 340px;
      }

      .inputLabel {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: white;
        text-align: left;
        // margin-top: 5px;
        margin-bottom: 10px;

        @media (max-width: 600px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .MuiInputBase-root {
        padding: 12px 20px;
        max-width: 353px;
        height: 54px;
        border: 1px solid white;
        border-radius: 6px;

        @media (max-width: 600px) {
          max-width: 330px;
          height: 52px;
        }

        input {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: white;
        }

        &:after,
        &:before {
          border-bottom: 0 !important;
        }
      }
    }

    .enterRfid_textSection {
      display: flex;
      // justify-content: space-between;
      align-items: flex-end;
      margin-top: 5px;

      @media (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        margin-top: -5px;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: baseline;
        gap: 0px;
      }

      .arrowInput_img {
        display: flex;
        align-items: center;
      }

      .inputLabel {
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: white !important;
        text-align: left;
        // margin-top: 5px;
        margin-bottom: 10px;

        @media (max-width: 600px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      // .MuiInputBase-root {
      //   padding: 12px 20px;
      //   min-width: 353px;
      //   height: 54px;
      //   border: 1px solid #808191;
      //   border-radius: 6px;

      //   @media (max-width: 600px) {
      //     max-width: 387px;
      //     height: 52px;
      //     gap: 0px
      //   }

      //   @media (max-width: 400px) {
      //     min-width: 323px;
      //     height: 52px;
      //   }

      //   @media (max-width: 380px) {
      //     min-width: 295px;
      //     height: 52px;
      //   }

      //   input {
      //     font-weight: 400;
      //     font-size: 18px;
      //     line-height: 25px;
      //     color: #000000;
      //   }

      //   &:after,
      //   &:before {
      //     border-bottom: 0 !important;
      //   }
      // }
    }

    .labelText_checkbox {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: white;
      margin-bottom: 0;

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0;
      }

      span {
        color: white !important;
        text-decoration: underline;
      }
    }

    button {
      padding: 15px 0px;
      width: 330px;
      height: 57px;
      background: transparent;
      border: 2px solid #ffd667;
      border-radius: 6px;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #ffd667;
      margin-top: 40px;
      box-shadow: none;

      @media (min-width: 375px) and (max-width: 786px) {
        width: 100% !important;
        height: 55px;
        border-radius: 6px;
        font-weight: 600;
        font-size: 20px;
      }

      &:disabled {
        background: lightgrey;
        color: white;
        border: none;
      }
    }

    button:hover {
      background: #ffd667;
      color: white;
      transition: all ease-in 0.2s;
    }
  }
}

//arrow image @media queries
@media (max-width: 912px) {
  .arrowInput_img {
    transform: rotate(90deg);
  }
}

// @media (max-width:768px) {
//   .arrowInput_img {
//     position: relative;
//     top: 221px;
//     transform: rotate(90deg);
//     margin-right: 10px;
//   }
// }

// @media (max-width:540px) {
//   .arrowInput_img {
//     position: relative;
//     top: 138px;
//     transform: rotate(90deg);
//     margin-right: 10px;
//   }
// }

// @media (max-width :500px) {
//   .arrowInput_img {
//     // display: none;
//     // margin-top: 26px;
//     top: 116px;
//   }
// }

// @media (max-width :414px) {
//   .arrowInput_img {
//     margin-top: 26px;
//     margin-right: 60px !important;
//     margin-bottom: 21px !important;
//   }
// }

// @media (max-width :375px) {
//   .arrowInput_img {
//     margin-top: 2px;
//     margin-right: 60px !important;
//     margin-bottom: 25px !important;
//   }
// }

@media (max-width: 820px) {
  .topupCard_main_container .formArea_topup_main .enterRfid_textSection {
    // margin-left: 15px;
    // margin-right: 15px;
    flex-direction: column;
    align-items: baseline;

    .arrowInput_img {
      align-self: flex-start !important;
      transform: rotate(90deg);
      margin-bottom: 25px;
    }
  }
}

@media (max-width: 930px) {
  .topupCard_main_container .formArea_topup_main .enterRfid_textSection {
    // margin-left: 15px;
    // margin-right: 15px;
    flex-direction: column;
    align-items: baseline;

    .arrowInput_img {
      align-self: flex-start !important;
      transform: rotate(90deg);
      margin-bottom: 25px;
    }
  }
}

@media (max-width: 414px) {
  .topupCard_main_container .formArea_topup_main .enterRfid_textSection {
    // margin-left: 15px;
    // margin-right: 15px;
    .arrowInput_img {
      align-self: center;
    }
  }
}

fieldset {
  border: 1px solid white !important;
  outline: white !important;
}

.inputLabel {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: white;
  text-align: left;
  // margin-top: 5px;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 375px) {
  .css-1pu2jq9-MuiGrid-root > .MuiGrid-item {
    padding-left: 0px !important;
  }
}

@media (max-width: 375px) {
  .css-klrhz-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    max-width: 322px !important;
  }
}

@media (min-width: 0px) {
  .css-uredqc-MuiFormControl-root-MuiTextField-root {
    min-width: 290px;
  }
}

.topupCard_main_container > div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.formArea_topup_main > div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.checkbox {
  color: white !important;
}

.formArea_topup_main .form-group > label {
  color: white !important;
}

.formArea_topup_main .form-group > div > div {
  color: white !important;
}

.formArea_topup_main .form-group > div > div > input::placeholder {
  color: white !important;
}

.formArea_topup_main .form-group > div > div > input:disabled {
  color: white !important;
  -webkit-text-fill-color: white !important;
  opacity: 1 !important;
}

.languageDropdown_dashboard > label {
  color: white !important;
}

.languageDropdown_dashboard > div > div > svg {
  fill: white !important;
}
